import { useState, useEffect } from 'react';
import Button from 'atoms/button'
import ReactPlayer from 'react-player/lazy';

export default function HomepageHero() {
  const [hasWindow, setHasWindow] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setHasWindow(true);
    }
  }, []);

  return (
    <>
      <div className="fold-above">
        <div className="container">
          <div className="row hero">
            <div className="col-12 col-lg-4 text-center">
              <h1 className="text-white">Ontdek de oplossing voor jouw klacht!</h1>
              <p className="text-white">De grootste pijn community van Nederland</p>
              <div>
                <Button type="" className="btn btn-orange btn-tertiary" target="_blank" link="https://pijnvrij.fysiotherapie4all.nl/producten/">
                  Start hier &nbsp;
                  
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-right-fill" viewBox="0 0 16 16">
                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
                  </svg> 
                </Button>
              </div>
            </div>
            <div className="col-12 col-lg-6 mt-5 mt-lg-0 offset-0 offset-lg-2 video-player">
              { hasWindow && <ReactPlayer
                url="https://vimeo.com/361062315"
                controls
                width="100%"
                height="100%"
                light="https://i.vimeocdn.com/video/815408474-55ed8351d0f4638642feeb91f3a0c9e72d8a62d75aa0b4ac93932c0078bd7624-d"
              /> }
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        .fold-above {
          /*background: url('/img/backgrounds/specialismen-above-fold.png');*/
          background-color: rgb(22, 168, 244);
          /*background-size: 200%;*/
          background-repeat: no-repeat;
          /*background-size: cover;*/
          background-position: center;
        }

        .btn-orange {
          max-width: 300px;
        }

        .video-player {
          min-height: 250px;
        }
      `}
      </style>
      <style jsx global>{`
      .fold-above .hero .btn {
        max-width: 300px;
      }

      .react-player__preview {
        background-size: contain !important;
      }
      `}</style>
    </>
  )
}
