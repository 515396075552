import NextImage from 'next/legacy/image'

export default function Image({ 
  className,
  src,
  boxShadow = false,
  square = false,
  fullWidth = false,
  alt = "",
  width = undefined,
  height = undefined,
  optimized = false,
  ...props
}) {
  const additionalClassNames = []
  if (!optimized && boxShadow) {
    additionalClassNames.push('enable-shadows')
  }

  if (!square) {
    additionalClassNames.push('img-rounded')
  }

  if (fullWidth) {
    additionalClassNames.push('full-width')
  }

  return (
    <>
      {!optimized && <img
        className={`${className} img-fluid ${additionalClassNames.join(' ')}`}
        src={src}
        alt={alt}
        width={width}
        height={height}
        loading="lazy"
        {...props}
      />}
      {optimized && <div className={`${square ? '' : 'img-container-rounded'} ${boxShadow ? 'enable-shadows' : ''}`}>
        <NextImage
          className={`${className} img-fluid ${additionalClassNames.join(' ')}`}
          src={src}
          alt={alt}
          width={width}
          height={height}
          {...props}
        />
      </div>}
      <style global jsx>{`
        img.full-width {
          width: 100%;
        }

        img.img-rounded {
          border-radius: 20px;
        }

        .img-container-rounded {
          border-radius: 20px;
        }

        .enable-shadows {
          box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.5);
        }

        img.no-radius {
          border-radius: 0;
        }

        img.img-round {
          border-radius: 50%;
          box-shadow: none !important;
        }
        
        img.img-vertical {
          height: 100%;
          margin-top: -50%;
          object-fit: cover;
        }

        img.img-plain {
          margin-top: 0;
          border-radius: 0;
          position: relative;
          margin-left: -30%;
          z-index: 0;
          min-width: 170%;
        }

        img.img-thumbnail {
          max-width: 130px;
          max-height: 130px;
        }

        @media screen and (max-width: 991px) {
          img.img-vertical {
            margin-top: 50px;
            max-height: 500px;
          }

          img.img-plain {
            margin-left: 0;
            min-width: auto;
            width: 100%:
          }
        }
      `}</style>
    </>
  )
}
